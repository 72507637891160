import axios from 'axios'
import { MessageBox/*, Message*/ } from 'element-ui'
import store from '@/store'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters['user/token']) {
      config.headers['X-Token'] = store.getters['user/token']
      //config.headers['X-Tid'] = store.getters['user/uid']
      //config.headers['X-Expiry'] = store.getters['user/expiry'] || 0
    //   config.headers['X-Lang'] = store.getters.language
    }
    return config
  },
  error => {
    // do something with request error
    // console.log('---onRejected:', error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // 100004: Illegal token; 100005: Other clients logged in; 50014: Token expired
    if (res.status === 100004 || res.status === 100005) {
      // to re-login
      const msg = '系统已退出登录，可以点击取消按钮停留在此页面，或者点击重新登录。'
      MessageBox.confirm(msg, '确认', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        store.dispatch('user/logout').then(() => {
          location.reload()
        })
      }).catch(() => {
      })

      return res
    }

    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== -1) {
      const msg = (res.msg + ' error code: ' + res.status) || 'Error'
      // Message({
      //   message: msg,
      //   type: 'error',
      //   duration: 5 * 3000,
      //   showClose: true
      // })

      res['err'] = msg
      return res
    } else {
      return res
    }
  },
  error => {
    /*Message({
      message: error.message,
      type: 'error',
      duration: 15 * 1000,
      showClose: true
    })*/
    return { 'err': error }
  }
)

export default service
